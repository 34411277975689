






























import { defineComponent } from '@nuxtjs/composition-api';
import { SfNotification } from '@storefront-ui/vue';
import { useUiNotification } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'NotificationBar',
  components: {
    SfNotification,
    SvgImage
  },
  setup () {
    const { notifications } = useUiNotification();

    return {
      notifications
    };
  }
});
